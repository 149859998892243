import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/spindle.ameba.design/spindle.ameba.design/src/components/Layout.tsx";
import SEO from '../../../components/SEO';
import ContentsList from '../../../components/ContentsList';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageTitle = makeShortcode("PageTitle");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="アイコン - スタイル" mdxType="SEO" />
    <PageTitle title="アイコン" enTitle="Iconography" mdxType="PageTitle" />
    <p>{`有機的意匠とシステム的な役割を融合させたAmebaらしいアイコンとして、ブランドタイポグラフィで使用されているAmeba Sansに着想を得て設計しています。`}</p>
    <ContentsList list={[{
      url: "/styles/iconography/anatomy/",
      title: "解剖",
      enTitle: "Anatomy",
      summary: "有機的意匠とシステム的な役割を融合させたAmebaらしいアイコンとして、ブランドタイポグラフィで使用されているAmeba Sansに着想を得て設計しています。"
    }, {
      url: "/styles/iconography/icons/",
      title: "一覧",
      enTitle: "Icons",
      summary: "Amebaで使用しているアイコンの一覧を掲載しています。"
    }, {
      url: "/styles/iconography/developer/",
      title: "開発者向け",
      enTitle: "For Developers",
      summary: "開発者へ向けての利用方法を掲載しています。"
    }]} mdxType="ContentsList"></ContentsList>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      